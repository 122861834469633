import React, { useState } from 'react';
import { Link } from 'react-router-dom';


const Navbar = () => {
    const [expanded, setExpanded] = useState(false);

    const handleNavItemClick = () => {
        setExpanded(false);
    };

    return (
        <>
            <nav className="navbar navbar-expand-lg ">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" >
                        <b> DR. NAVNEET  MISHRA</b>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setExpanded(!expanded)}>
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className={`collapse navbar-collapse ${expanded ? 'show' : ''}`} id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className="nav-link" onClick={handleNavItemClick}>HOME</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/About" className="nav-link" onClick={handleNavItemClick}>ABOUT</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown1" role="button" data-bs-toggle="dropdown" aria-expanded="false">TREATMENTS</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown1">

                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Enlarged Prostate</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Urinary Stone Disease</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Urethral Stricture Disease</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Laparoscopic Urology</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Paediatric Urology</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Urological Cancers</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Urinary Incontinence</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Urinary Infections</Link></li>



                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link to="#" className="nav-link dropdown-toggle" id="navbarDropdown2" role="button" data-bs-toggle="dropdown" aria-expanded="false">SPECIAL INSTRUMENTATION</Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown2">
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Laser</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>C-ARM</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Uroflowmetry</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Karlstor Laparoscopy</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Karlstor RIRS</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Vessel Sealing (Covidien)</Link></li>
                                    <li><Link to="/" className="dropdown-item" onClick={handleNavItemClick}>Dialysis & Transplant</Link></li>
                                </ul>
                            </li>


                            <li className="nav-item">
                                <Link to="/Gallery" className="nav-link" onClick={handleNavItemClick}>GALLERY</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/Register" className="nav-link" onClick={handleNavItemClick}>CONTACT</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Navbar;
