import React, { useContext, useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import Footer from './Footer';

const Register = () => {

  const [inpval, setInp] = useState({
    name: "",
    email: "",
    age: "",
    mobile: "",
    work: "",
    address: "",
    description: "",
  });

  const setData = (e) => {
    const { name, value } = e.target;
    setInp(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const addinpdata = async (e) => {
    e.preventDefault();
    const { name, email, age, mobile, work, address, description } = inpval;
    const res = await fetch("/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        name, email, age, mobile, work, address, description
      })

    }

    );
    const data = await res.json();
    console.log(data);
    if (res.status === 404 || !data) {
      alert("error");
      console.log("error");
    }
    else {
      Swal.fire({
        icon: 'success',
        title: 'Data Submitted Successfully',
        showConfirmButton: false,
        timer: 1000
      });

      // history.push("/")
      console.log("Data Saved");
    }

  }




  return (


    <>

      <div className='container'>


        <form className='mt-5' onSubmit={addinpdata} >
          <div className='row'>
            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="name" className="form-label">Name</label>
              <input type="text" value={inpval.name} onChange={setData} name="name" className="form-control" id="name" />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="email" className="form-label">Email</label>
              <input type="email" value={inpval.email} onChange={setData} name="email" className="form-control" id="email" />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="age" className="form-label">Age</label>
              <input type="text" value={inpval.age} onChange={setData} name="age" className="form-control" id="age" />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="mobile" className="form-label">Mobile</label>
              <input type="text" value={inpval.mobile} onChange={setData} name="mobile" className="form-control" id="mobile" />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="work" className="form-label">Work</label>
              <input type="text" value={inpval.work} onChange={setData} name="work" className="form-control" id="work" />
            </div>

            <div className="mb-3 col-lg-6 col-12">
              <label htmlFor="address" className="form-label">Address</label>
              <input type="text" value={inpval.address} onChange={setData} name="address" className="form-control" id="address" />
            </div>

            <div className="mb-3 col-lg-12">
              <label htmlFor="description" className="form-label">description</label>
              <textarea name="description" value={inpval.description} onChange={setData} className="form-control" id="description" rows="3"></textarea>
            </div>

            <button type="submit" onClick={addinpdata} className="btn btn-primary">Submit</button>
          </div>
        </form>

      </div>

      <Footer />

    </>
  );
}

export default Register;
