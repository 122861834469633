import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import banner1 from './img/banner1.jpg';
import banner2 from './img/banner2.jpg';
import banner3 from './img/banner3.jpg';

function Carposal() {
    return (
        <Carousel>
            <Carousel.Item className='slider'>
                <img className="d-block w-100" src={banner1} alt="First slide" />
                <Carousel.Caption>
                  
                    <p>Best Urologist  in lucknow  </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className='slider'>
                <img className="d-block w-100" src={banner2} alt="Second slide" />
                <Carousel.Caption>
               
                <p>Best Urologist  in lucknow  </p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className='slider'>
                <img className="d-block w-100" src={banner3} alt="Third slide" />
                <Carousel.Caption>
                
                <p>Best Urologist  in lucknow  </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default Carposal;
