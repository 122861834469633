import React from 'react'
import video from './video/video.mp4'
import doctor from './img/doctor.jpg'

const Section = () => {
    return (
        <div className='section'>

            <div className='section2'>
                <h1>Welcome To Dr. <br/><span>  Navneet Mishra</span>  </h1>
                <p>Dr. Navneet Mishra , a well known Urologist and   Renal Transplant Surgeon in Wellsun Medicity & Super Speciality Hospital in Lucknow. He has clinical experience of 10 years. He offers wide range of surgery such as Laparoscopic Urological Surgery & Renal transplant, Laser Surgeries, Stone Surgery, Urological Malignancies ,
                    Prostate Surgeries, Urethral Surgeries.</p>
                <p>Dr. Navneet Mishra is an experienced Urologist in Lucknow. He has been a practicing Urologist for 10 years. He has done M.B.B.S, M.S from KGMU, M.Ch – Urology from SGPGI and then worked as Assistant Professor in Department of Urology in KGMU, Chowk Lucknow. Now he is working as a Consultant Urology & Renal Transplant Surgeon in Wellsun Medicity Hospital, Lucknow & doing his OPD’s in Mishra Polyclinic & Hospital.</p>
                <p>He is committed to enhancing access & ensuring high quality, economical & comprehensive Urological treatment.</p>

            </div>

            <div className='section2'>
                {/* <video controls autoplay loop muted>
                    <source src={video} type="video/mp4" />
                </video> */}
                <img src={doctor}/>
            </div>

        </div>
    )
}

export default Section