import React from 'react'

const Map = () => {
    return (
        <>
            <div className='maps'>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14239.15019343835!2d80.9461592!3d26.8467088!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399bfd487a876297%3A0xb5391bc661561ad8!2sPOLICE%20STATION%20GOSAIGANJ!5e0!3m2!1sen!2sin!4v1714367862568!5m2!1sen!2sin " style={{width:'100%',height:'100%'}}></iframe>
            </div>
        </>
    )
}

export default Map
