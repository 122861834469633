import React from 'react'
import Carposal from './Carposal'
import Section from './Section'
import Services from './Services'
import Footer from './Footer'
import Map from './Map'

const Home = () => {
  return (
    <>

      <div className='main'>
        <Carposal />
        <Section />
        <Services />
        <Map />
        <Footer />
      </div>

    </>
  )
}

export default Home