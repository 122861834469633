import React from 'react';
import Footer from '../Footer';


import gal1 from '../img/card.png';
import gal2 from '../img/enlargeprost.jpg';
import gal3 from '../img/lapro.jpg';
import gal4 from '../img/pedia.jpg';
import gal5 from '../img/urethrel.jpg';
import gal6 from '../img/urocancer.jpg';

const Gallery = () => {
  return (
    <>
      <div className='gallery'>
        <img src={gal1} alt='Image 1' />
        <img src={gal2} alt='Image 2' />
        <img src={gal3} alt='Image 3' />
        <img src={gal4} alt='Image 4' />
        <img src={gal5} alt='Image 5' />
        <img src={gal6} alt='Image 6' />
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
