import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './media.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.js';
import Navbar from './component/Navbar';
import Home from './component/Home';
import Register from './component/Register';
import Head from './component/Head.js';
import About from './component/pages/About.js';
import Gallery from './component/pages/Gallery.js';


function App() {
  return (

    <div className='main'>

      <Router>
        <Head />
        <Navbar />
        <Routes>

          <Route path="*" element={<Home />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/About" element={<About />} />
          <Route path="/Gallery" element={<Gallery />} />

        </Routes>
      </Router>
    </div>

  );
}

export default App;
