import React from 'react'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';

const Head = () => {
    return (
        <div className='head'>
            <div className='head2'>
                <p>navneetmishrakgmu@gmail.com</p>
            </div>
            <div className='head2' style={{ display: 'flex' }}>

                <a href='' style={{ color: 'green' }}><WhatsAppIcon /></a>
                <a href='' style={{ color: 'blue' }}><FacebookIcon /></a>
                <a href='' style={{ color: 'red' }}><InstagramIcon /></a>
                <a href='' style={{ color: 'purple' }}><YouTubeIcon /></a>

                <p>+91 6307460051</p>
            </div>
            <div className='head2'>
                <p>Gumti No-5, near Gosaiganj Police Station, Lucknow, Gosainganj, Uttar Pradesh 226501</p>
            </div>
        </div>
    )
}

export default Head
