import React from 'react'
import Section from '../Section';
import Footer from '../Footer';

const About = () => {
  return (
   <>
   <Section/>
   <br/><br/>
   <Footer/>
   </>
  )
}

export default About