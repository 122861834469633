import React from 'react'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import protest from './img/enlargeprost.jpg'
import urinary from './img/urinary.jpg'
import urethrel from './img/urethrel.jpg'
import laprosco from './img/lapro.jpg'
import pedia from './img/pedia.jpg'
import cancer from './img/urocancer.jpg'
import incont from './img/incontenece.jpg'
import infect from './img/urinfcion.jpg'


const Services = () => {
    return (
        <>

        <br/>
            <center><h1>Our Treatments</h1></center>

            <div className='services'>

                <Card className='serve2'>
                    <Card.Img variant="top" src={protest} />
                    <Card.Body className='backs'>
                        <Card.Title>Enlarged Prostate</Card.Title>
                        <Card.Text className='texts'>
                            BPH doesn't have a cure, but treatments can help alleviate your symptoms. Mild symptoms may not require treatment. Medications, surgery and minimally invasive treatments can treat more severe cases.
                        </Card.Text>
                        <Button variant="primary">Read More </Button>
                    </Card.Body>
                </Card>

                <Card className='serve2'>
                    <Card.Img variant="top" src={urinary} />
                    <Card.Body className='backs'>
                        <Card.Title>Urinary Stone Disease</Card.Title>
                        <Card.Text className='texts'>
                            Possible causes include drinking too little water, exercise (too much or too little), obesity, weight loss surgery, or eating food with too much salt or sugar
                        </Card.Text>
                        <Button variant="primary">Read More </Button>
                    </Card.Body>
                </Card>

                <Card className='serve2'>
                    <Card.Img variant="top" src={urethrel} />
                    <Card.Body className='backs'>
                        <Card.Title>Urethral Stricture Disease</Card.Title>
                        <Card.Text className='texts'>
                            A urethral (u-REE-thrul) stricture involves scarring that narrows the tube that carries urine out of the body, called the urethra. As a result of a stricture, less urine comes out of the bladder
                        </Card.Text>
                        <Button variant="primary">Read More </Button>
                    </Card.Body>
                </Card>

                <Card className='serve2'>
                    <Card.Img variant="top" src={laprosco} />
                    <Card.Body className='backs'>
                        <Card.Title>Laparoscopic Urology</Card.Title>
                        <Card.Text className='texts'>
                            Laparoscopic urologic surgery is a surgery where a doctor corrects urological issues without causing much agony to the patient.
                        </Card.Text>
                        <Button variant="primary">Read More </Button>
                    </Card.Body>
                </Card>
            </div>


            <div className='services'>

                <Card className='serve2'>
                    <Card.Img variant="top" src={pedia} />
                    <Card.Body className='backs'>
                        <Card.Title>Paediatric Urology</Card.Title>
                        <Card.Text className='texts'>
                        Paediatric urology has recently emerged in many European countries as a distinct subspecialty of both urology and paediatric surgery 
                        </Card.Text>
                        <Button variant="primary">Read More </Button>
                    </Card.Body>
                </Card>

                <Card className='serve2'>
                    <Card.Img variant="top" src={cancer} />
                    <Card.Body className='backs'>
                        <Card.Title>Urological Cancers</Card.Title>
                        <Card.Text className='texts'>
                        Three of the most common cancers (prostate, bladder and testis) fall within the remit of urologists.
                        </Card.Text>
                        <Button variant="primary">Read More </Button>
                    </Card.Body>
                </Card>

                <Card className='serve2'>
                    <Card.Img variant="top" src={incont} />
                    <Card.Body className='backs'>
                        <Card.Title>Urinary Incontinence</Card.Title>
                        <Card.Text className='texts'>
                        Urinary incontinence — the loss of bladder control — is a common and often embarrassing problem. The severity ranges from occasionally leaking urine 
                        </Card.Text>
                        <Button variant="primary">Read More </Button>
                    </Card.Body>
                </Card>

                <Card className='serve2'>
                    <Card.Img variant="top" src={infect} />
                    <Card.Body className='backs'>
                        <Card.Title>Urinary Infections</Card.Title>
                        <Card.Text className='texts'>
                        Urinary tract infections (UTIs) are usually caused by bacteria from poo entering the urinary tract.
                        </Card.Text>
                        <Button variant="primary">Read More </Button>
                    </Card.Body>
                </Card>
            </div>



        </>
    )
}

export default Services
